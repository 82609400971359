<!--banner图-->
<template>
    <div class="uploadBox flex flex-wrap">
        <div v-for="(item,index) in formData.image" :key="index" >
            <div class="flex flex-column" style="width:375px;margin-right:20px;margin-bottom:20px">
                <vue-hover-mask>
                    <!-- 默认插槽 -->
                    <el-image :src="domain + item.img_url" style="width:100%;height:180px"  fit="cover" @click="handleFilePreview(index)"></el-image>
                    <!-- action插槽 -->
                    <template v-slot:action>
                        <div class="flex flex-ai-c flex-jc-c" style="height:100%">
                            <el-upload
                                style="width:20px;height:20px;margin-right:30px"
                                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                                :action="imgurl()"
                                name="pic"
                                :show-file-list="false"
                                :limit="1"
                                :with-credentials="credentials"
                                :on-success="function (res) { return handleEditFileSuccess(res,index)}"
                            >
                                <i class="el-icon-edit" style="font-size:20px"></i>
                            </el-upload>
                            <i class="el-icon-delete-solid" @click="clearImage(index)"></i>
                        </div>
                    </template>
                </vue-hover-mask>
                <el-input v-model="item.goods_url" placeholder="请输入跳转活动编号" @change="updateGoodsurl(item,index)" autocomplete="off" style="width:100%;margin-top:10px"></el-input>
            </div>
        </div>
        <el-upload
            v-if="formData[clickFile].length<5"
            class="upload-demo"
            style="height:222px"
            drag
            accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
            :action="imgurl()"
            name="pic"
            :show-file-list="false"
            :limit="limitNum"
            :with-credentials="credentials"
            :on-success="handleFileSuccess"
            :on-preview="handleFilePreview"
            :on-remove="handleFileRemove"
            :on-exceed = "handleFileExceed"
        >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
        </el-upload>

        <el-image-viewer
           v-if="dialogImageVisible"
           :on-close="closeViewer"
           :url-list="[dialogImageUrl]" 
        />
    </div>
</template>
<script>
    // import viewPicture from '@/components/viewPicture';
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
    import VueHoverMask from 'vue-hover-mask'
    export default {
        data() {
            return {
                showFileList:[], //展示图片列表
                uploadFileList:[], //上传图片列表
                dialogImageUrl:'',
                dialogImageVisible:false,
                credentials:true,
                formData:{
                    image:[]
                },

                imageArr:[]
            }
        },
        props: [
            'imageList',
            'clickFile',
            'limitNum'
        ],
        components:{
            ElImageViewer,
            VueHoverMask
        },
        mounted() {
           this.init() 
        },
        methods: {
            init(){  
                //读取商品详情轮播图数据组合
                let _this = this;
                this.formData[this.clickFile] = [];
                setTimeout(function(){
                    _this.formData[_this.clickFile] = _this.imageList;
                    // for(let image of _this.imageList){
                    //     _this.imageArr.push({
                    //         url:this.domain + image.url
                    //     })
                    // }
                    // _this.showFileList = _this.imageArr;
                    // console.log(_this.showFileList)
                },300)
            },

            //获取接口地址
            imgurl() {
                return this.domain + "/upload/upFile"; //接口地址
            },
            //图片上传成功的函数
            handleFileSuccess(response) {
                if (parseInt(response.code) != 200) return this.$message({
                    type: 'warning',
                    message: '上传失败，请重试'
                })
               
                this.formData[this.clickFile] = this.imageList;
                this.formData[this.clickFile].push({
                    img_url:response.data.path
                })
                this.$emit("upload", this.formData);
            },
            handleEditFileSuccess: function(response,index){
                console.log(response)
                console.log(index)
                if (parseInt(response.code) != 200) return this.$message({
                    type: 'warning',
                    message: '上传失败，请重试'
                })
               
                this.formData[this.clickFile] = this.imageList;
                this.formData[this.clickFile][index].img_url = response.data.path;
                console.log(this.formData[this.clickFile])
                this.$emit("upload", this.formData);
            },
            //清除图片
            clearImage:function(index){
                this.formData[this.clickFile].splice(index,1);
                this.$emit("upload", this.formData);
            },
            //编辑图片
            editImage: function(index){
                console.log(index)
                // this.formData[this.clickFile][index].img_url = ;
            },

            //移除文件时的钩子函数
            handleFileRemove(file) {
                if (file.status != "success") return this.$message({
                    type: 'warning',
                    message: '删除失败，请重试'
                })
                const index = this.formData[this.clickFile].findIndex((cur) => {
                    return cur.img_url === file.url
                })
               
                this.formData[this.clickFile].splice(index, 1);
                this.$emit("upload", this.formData);
            },

            updateGoodsurl: function(){
                this.$emit("upload", this.formData);
            },
            
            // 预览图片
            handleFilePreview(index) {
                this.dialogImageUrl = this.domain + this.formData[this.clickFile][index].img_url;
                this.dialogImageVisible = true;
            },
            // 关闭查看器
            closeViewer() {
                this.dialogImageVisible = false
            },
            //文件上传超出
            handleFileExceed(files,fileList){
                if(this.limitNum == fileList.length){
                    this.$message.error(`只允许上传${this.limitNum}张图片！`);
                }
            },
        }
    }
</script>
<style lang="less">
    .upload-demo{
        .el-upload,.el-upload-dragger{
            height: 100%;
        }
        .el-upload-dragger{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .el-icon-upload{
                margin-top: 0;
            }
        }
        
    }
</style>