<!--banner管理-->
<template>
    <div class="mainBox">
        <div class=" bannerBox table">
            <el-form ref="formAuth" :model="formData" label-width="150px" label-position="right" size="small" class="alertForm" style="height:calc(100% - 50px);overflow: auto;">
                <el-form-item label="上 传 轮 播 图 片  （尺寸375*200）" style="width:100%">
                    <bannerUpload :imageList = "formData.image" :limitNum="limit" :clickFile="'image'" @upload="uploadFile"></bannerUpload>
                </el-form-item>
                <!-- <el-form-item label="添加跳转链接地址：" style="width:100%;padding-right:1%">
                    <el-input v-model="formData.goods_url" placeholder="请输入跳转url链接地址" autocomplete="off"></el-input>
                </el-form-item> -->
            </el-form>

            <div style="position:absolute;bottom:0;left: 0;right: 0;">
                <el-button type="primary" plain size="small" @click="submit" style="width:100%">保存</el-button>
            </div>

        </div>
    </div>
</template>
<script>
    import bannerUpload from '@/components/bannerUpload';
    import {configBannerSaveApi,configBannerListApi}  from '@/api/config.js';
    export default {
        data() {
            return {
                image:[],
                formData:{
                    image:[],
                    goods_url:''
                },
                limit:5
            }
        },
        components:{
            bannerUpload
        },
        mounted() {
            this.readBanner();
        },
        methods: {
            uploadFile:function(file){
                // this.image = [];
                this.formData.image = file.image;
                // for(let item of file.image){
                //     this.image = item.url;
                // }
            },
            //读取banner图片详情
            readBanner: function(){
                this.formData.image = [];
                configBannerListApi().then(response=>{
                    for(let item of response.list){
                        this.formData.image.push({
                            goods_url:item.goods_url,
                            img_url:item.img_url
                        })
                    }
                    //banner图片显示需要格式
                    // this.formData.image = response.list;
                    //banner图片保存格式
                    // this.image = response.img_url;
                    //图片对应ID
                    // this.formData.goods_url = response.goods_url;
                })
            },
            submit: function(){
                if(this.formData.image.length == 0){
                    this.$message({
                        type: 'error',
                        message: '请上传图片!'
                    });
                    return;
                }
                let image = [];
                for(let item of this.formData.image){
                    image.push({
                        img_url:item.img_url,
                        goods_url:item.goods_url ? item.goods_url : ''
                    })
                }
                configBannerSaveApi({
                    content:image
                }).then(()=>{
                    this.readBanner();
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                })
            }
        },
    }
</script>